/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ThemeProvider } from 'styled-components'
import { ConfirmSwal } from 'vspry-style-components'
import { Helmet } from 'react-helmet'

import theme from 'styles/theme'
import GlobalStyle from 'styles/globalStyles'

import ViewController from 'views/ViewController'

// providers
import { MessagingProvider } from 'context/messageContext'
import { SSOConfig, SSOProvider } from 'context/ssoContext'
import { LocaleProvider } from 'context/localeContext'
import { AuthProvider } from 'context/authContext'
import { AppConfigProvider } from 'context/appConfigContext'

import NotificationHandler from 'components/notifications/NotificationHandler'
import { ClaimsProvider } from 'context/claimsContext'
import { RedemptionsProvider } from 'context/redemptionsContext'
import AppInstaller from 'components/AppInstaller'
import { useEffect } from 'react'

const url = `https://${window.location.host}`

const authConfig: SSOConfig = {
    clientId: window.configuration['EXT_SSO_CLIENT_ID'],
    authorizationEndpoint: window.configuration['EXT_SSO_AUTH_ENDPOINT'],
    tokenEndpoint: window.configuration['EXT_SSO_TOKEN_ENDPOINT'],
    redirectUri: `${url}/#/auth/callback`,
    // eslint-disable-next-line i18next/no-literal-string
    scope: 'profile openid offline_access',
    logoutEndpoint: window.configuration['EXT_SSO_LOGOUT_ENDPOINT'],
    logoutRedirect: url,
    onRefreshTokenExpire: (event) =>
        ConfirmSwal.fire({
            title: 'Session Expired',
            text: 'Your session has expired. Would you like to refresh your session?',
            iconColor: theme.text,
            confirmButtonColor: theme.primaryOBJ,
        }).then((r) => (r.value ? event.logIn() : event.logOut())),
    decodeToken: false,
    autoLogin: false,
    clearURL: false,
    // extraAuthParameters: { response_mode: 'form_post' },
    extraTokenParameters: { client_secret: window.configuration['EXT_SSO_CLIENT_SECRET'] },
    postLogin: (redirect) => {
        window.history.replaceState(null, '', `/#${redirect}`)
    },
}

export default function App() {
    useEffect(() => {
        if (window.configuration['FRESHCHAT_SRC']) {
            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.src = window.configuration['FRESHCHAT_SRC']
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            s.chat = true
            try {
                document.body.appendChild(s)
            } catch (e) {
                s.src = window.configuration['FRESHCHAT_SRC']
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                s.chat = true
                document.body.appendChild(s)
            }
            return () => {
                document.body.removeChild(s)
            }
        }
        return () => null
    }, [])
    return (
        <>
            <Helmet>
                <title>{window.configuration['MANIFEST_SHORT_NAME']}</title>
                <link rel='shortcut icon' href={`images/icons/${window.configuration['THEME_BRAND']}/favicon.ico`} />
            </Helmet>
            <AppInstaller />
            <GlobalStyle />
            <SSOProvider config={authConfig}>
                <AuthProvider>
                    <LocaleProvider>
                        <AppConfigProvider>
                            <ThemeProvider theme={theme}>
                                <MessagingProvider>
                                    <ClaimsProvider>
                                        <RedemptionsProvider>
                                            <NotificationHandler />
                                            <ViewController />
                                        </RedemptionsProvider>
                                    </ClaimsProvider>
                                </MessagingProvider>
                            </ThemeProvider>
                        </AppConfigProvider>
                    </LocaleProvider>
                </AuthProvider>
            </SSOProvider>
            <div id='recaptcha-wrapper'>
                <div id='recaptcha' />
            </div>
        </>
    )
}
